<template>
  <v-container>
    <v-card flat>
      <DialogImage
        v-if="dialogImage"
        :dialogImage.sync="dialogImage"
        :item="image"
      />
      <v-card-text class="pt-6">
        <div v-if="solicitacoesServicos.length">
          <v-card
            class="mb-3"
            v-for="(solicitacao, i) in solicitacoesServicos"
            :key="i"
            flat
            outlined
          >
            <v-card-title class="grey lighten-5 text-subtitle-1">
              <span class="font-weight-bold">
                {{ solicitacao.cliente_razao_social }}
              </span>

              <v-spacer></v-spacer>
              {{ solicitacao.nome_usuario }} -
              {{ solicitacao.created_at | dateFormat("dd/MM/yyyy  HH:mm:ss") }}
            </v-card-title>

            <v-divider> </v-divider>

            <v-card-text class="grey lighten-5 pa-4">
              {{ solicitacao.solicitacao }}
            </v-card-text>

            <!-- <v-divider></v-divider> -->

            <v-card-actions class="grey lighten-5 pa-4">
              <div v-for="(midia, j) in solicitacao.midias" :key="j">
                <v-btn
                  icon
                  v-if="midia.tipo == 'pdf'"
                  :href="midia.url"
                  target="_blank"
                >
                  <v-icon> mdi-file-pdf-box </v-icon>
                </v-btn>
                <v-btn
                  icon
                  v-else-if="midia.tipo == 'jpg' || midia.tipo == 'png'"
                  :href="midia.url"
                  target="_blank"
                >
                  <v-icon> mdi-image </v-icon>
                </v-btn>
                <v-btn icon v-else :href="midia.url" target="_blank">
                  <v-icon> mdi-file-document-outline </v-icon>
                </v-btn>
              </div>
              <v-spacer></v-spacer>
              <div>
                <v-btn
                  v-if="!solicitacao.atividade_id"
                  class="mr-3"
                  color="secondary"
                  @click="addAtividade(solicitacao)"
                >
                  <v-icon left>mdi-plus</v-icon>
                  Atividade
                </v-btn>

                <!-- <v-btn
                  v-if="solicitacao.atividade_id"
                  class="mr-3"
                  color="secondary"
                  @click="editAtividade(solicitacao)"
                >
                  <v-icon left>mdi-pencil</v-icon>
                  Atividade
                </v-btn> -->

                <span  v-if="solicitacao.atividade_id" class="mr-3 green--text">
                  <v-icon class="green--text">mdi-check</v-icon>
                  ATIVIDADE CADASTRADA
                </span>

                <v-btn
                  @click="solicitacaoLido(solicitacao.id, i)"
                  v-if="solicitacao.status === 0"
                  class="primary"
                >
                  OK
                </v-btn>
                <span v-else class="green--text">
                  <v-icon class="green--text">mdi-check</v-icon>
                  LIDO
                </span>
              </div>
            </v-card-actions>
          </v-card>
        </div>
        <div v-else class="d-flex align-center text-center justify-center">
          Nenhuma solicitação registrado até o momento!
        </div>
      </v-card-text>
    </v-card>

    <AtividadesNovoTrabalho
      v-if="dialogAddAtividade"
      :dialogAddAtividade.sync="dialogAddAtividade"
      :clienteComponent="true"
      :cliente_id="solicitacao.cliente_id"
      :solicitacao="solicitacao"
      @fetch-briefings="fetchSolicitacoesServicos"
    />

    <AtividadesEditarTrabalho
      v-if="dialogEditAtividade"
      :dialogEditAtividade.sync="dialogEditAtividade"
      :clienteComponent="true"
      :cliente_id="solicitacao.cliente_id"
      :atividadeSelected="solicitacao.atividade_id"
      @fetch-briefings="fetchSolicitacoesServicos"
    />
  </v-container>
</template>

<script>
import {
  getSolicitacoesServicos,
  putMsgLida,
} from "@/api/gestor/solicitacoes_servicos.js";
import { mapActions } from "vuex";

export default {
  name: "SolicitacoesServicos",

  props: {
    status: {
      type: Number,
    },
  },

  components: {
    AtividadesNovoTrabalho: () =>
      import("../atividades/components/AtividadesNovoTrabalho.vue"),
    AtividadesEditarTrabalho: () =>
      import("../atividades/AtividadesEditarTrabalho.vue"),
    DialogImage: () => import("./components/DialogImage.vue"),
  },

  data() {
    return {
      solicitacoesServicos: [],
      loading: false,
      image: null,
      dialogImage: false,
      dialogAddAtividade: false,
      dialogEditAtividade: false,
      solicitacao: null,
    };
  },

  methods: {
    fetchSolicitacoesServicos() {
      this.loading = true;
      getSolicitacoesServicos(`?&status=${this.status}`)
        .then((response) => {
          this.STORE_count_solicitacoes_servicos();
          this.solicitacoesServicos = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    solicitacaoLido(item, index) {
      putMsgLida(item).then((response) => {
        if (response.status === 200) {
          this.$toast.success("Solicitação Lida!");
          this.solicitacoesServicos[index].status = 1;
          this.STORE_count_solicitacoes_servicos();
        }
      });
    },

    ...mapActions("Solicitacoesservicos", {
      STORE_count_solicitacoes_servicos: "countSolicitacoesServicos",
    }),

    addAtividade(solicitacao) {
      this.solicitacao = { ...solicitacao };
      this.solicitacao.midias = [...solicitacao.midias];
      this.dialogAddAtividade = true;
    },

    editAtividade(solicitacao) {
      this.solicitacao = { ...solicitacao };

      this.dialogEditAtividade = true;
    },
  },

  mounted() {
    this.fetchSolicitacoesServicos();
  },
};
</script>

<style></style>
